.capper-pagination {
  background-color: var(--primary-16);
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &__list {
    display: flex;
    justify-content: center;
  }

  &__item {
    background-color: var(--primary-16);
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    margin: 0 5px;
    border-radius: 50px;
    border: 1px solid var(--t);

    &_first {

    }

    &_last {

    }

    &_prev {
      svg {
        transform: rotate(90deg);
      }
    }

    &_next {
      transform: rotate(-90deg);
    }

    &._active {
      border: 1px solid var(--primary-color);
    }

  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--primary-color);
  }

  &__dots {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
  }
}

@include respond-up('large') {
  .capper-pagination {
    height: 61px;

    &__item {
      margin: 0 10px;
      transition: {
        property: background-color;
        duration: var(--default-transition-timing);
      };

      &:hover {
        background-color: rgba(14, 157, 255, 0.3);
      }
    }
  }
}

@include respond-up('medium') {
  .capper-pagination {

  }
}

@include respond('medium') {
  .capper-pagination {

  }
}

@include respond-down('medium') {
  .capper-pagination {
    height: 61px;

    &__item {
      margin: 0 5px;
    }
  }
}

@include respond-down('small') {
  .capper-pagination {

  }
}