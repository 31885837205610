.article-block {
  &__content {
    display: flex;
    justify-content: center;
    padding: 0 var(--grid-spacer);
  }

  &__article {
    flex: 1 1 auto;
  }

  &__image-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__picture {
    width: 200px;
    //flex: 0 0 200px;
    display: flex;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &__image {
    width: 100%;
    flex: 0 0 100%;
    object-fit: contain;
    object-position: center top;
  }

  &__label {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    line-height: 17/14*1em;
    font-weight: 400;
    color: var(--color-bg);
  }
}


@include respond-up('large') {
  .article-block {

    &__image-wrap {
      flex: 0 0 260px;
    }
  }
}

@include respond-up('medium') {
  .article-block {

  }
}

@include respond('medium') {
  .article-block {

  }
}

@include respond-down('medium') {
  .article-block {

    &__content {
      flex-wrap: wrap;
    }

    &__image-wrap {
      padding-top: 24px;
      flex: 0 0 100%;
    }
  }
}

@include respond-down('small') {
  .article-block {

  }
}