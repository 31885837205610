.capper-info-top {
  &__capper {
    display: flex;
    align-items: center;

    &._green {
      background: var(--gradient-green);
    }

    &._blue {
      background: var(--primary-gradient);
    }
  }

  &__image-wrap {
    background-color: black;
    border-radius: 100px;
    overflow: hidden;
  }

  &__name {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.05em;
    color: white;
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-position: center;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .capper-info-top {
    &__capper {
      padding: 15px var(--grid-spacer);
    }

    &__image-wrap {
      border: 8px solid white;
      flex: 0 0 130px;
      width: 130px;
      height: 130px;
      margin-right: 28px;
    }

    &__name {
      font-size: 40px;
      line-height: 49/40;
    }
  }
}

@include respond-down('medium') {
  .capper-info-top {
    &__capper {
      padding: 10px var(--grid-spacer);
    }

    &__image-wrap {
      border: 4px solid white;
      flex: 0 0 80px;
      width: 80px;
      height: 80px;
      margin-right: var(--grid-gap);
    }

    &__name {
      font-size: 23px;
      line-height: 29/24*1em;
    }
  }
}

@include respond-down('small') {
  .capper-info-top {

  }
}