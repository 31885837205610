.index-page {
  &__before-main-content {

  }

  &__main-content {

  }

  &__sidebar-content {
  }

  &__plug {
    background-color: white;
    height: 300px;
    border-radius: 6px;
  }
}

@include respond-up('large') {
  .index-page {
    &__before-content {
      padding-top: var(--grid-spacer);
    }
  }
}

@include respond-up('medium') {
  .index-page {

  }
}

@include respond('medium') {
  .index-page {

  }
}

@include respond-down('medium') {
  .index-page {

  }
}

@include respond-down('small') {
  .index-page {

  }
}