.pagination-endless {

  &__button {
    margin-top: 31px;
    display: flex;
    color: var(--primary-color);
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 18/15*1em;
    text-align: center;
    letter-spacing: 0.05em;
    transition: {
      property: color;
      duration: var(--default-transition-timing);
    };
    &:hover {
      color: var(--primary-16-on-white);

      svg path{
        stroke: var(--primary-16-on-white);
      }
    }

    svg {
      margin-left: 5px;

      path {
        transition: {
          property: stroke;
          duration: var(--default-transition-timing);
        };
      }
    }
  }
}

@include respond-up('large') {
  .pagination-endless {
    &__button {
      margin-top: 31px;
      transition: {
        property: color;
        duration: var(--default-transition-timing);
      };
      &:hover {
        color: var(--primary-16-on-white);

        svg path{
          stroke: var(--primary-16-on-white);
        }
      }

      svg {
        path {
          transition: {
            property: stroke;
            duration: var(--default-transition-timing);
          };
        }
      }
    }
  }
}

@include respond-up('medium') {
  .pagination-endless {

  }
}

@include respond('medium') {
  .pagination-endless {

  }
}

@include respond-down('medium') {
  .pagination-endless {
    &__button {
      margin-top: 22px;
    }
  }
}

@include respond-down('small') {
  .pagination-endless {

  }
}