article {
  &, p, li {
    color: var(--color-bg-light);
    font-size: 16px;
    line-height: 150%;
  }

  h1, h2, h3, h4, strong {
    color: var(--color-bg);
    line-height: 150%;
  }

  strong {
    font-weight: bold;

  }

  em {
    font-style: italic;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ul, ol {
    margin: 10px 0;
  }

  ul {
    li {
      position: relative;
      padding: 5px 0 5px 30px;

      &::before {
        content: '';
        position: absolute;
        left: 11px;
        top: 13px;
        background-color: var(--primary-color);
        width: 7px;
        height: 7px;
        border-radius: 10px;
      }
    }
  }
}


@include respond-up('large') {
  article {
    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    }
    h2 {
      font-size: 25px;
    }

    h3 {
      font-size: 20px;
    }
  }
}

@include respond-down('medium') {
  article {
    h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
    }

    &, p, li {
      font-size: 15px;
    }

    strong {
      font-size: 17px;
    }
  }
}