.capper-info-bottom {
  background-color: var(--primary-16);

  &__list {
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__name {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--primary-color);
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__value-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__value-text {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--primary-color);
  }

  &__value-name {
    margin-top: 7px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19/16*1em;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: var(--primary-color);
  }


  &__link {
    display: flex;
    align-items: center;
    background-color: var(--primary-16);
    padding: 9px 0 11px;
    justify-content: center;
  }

  &__link-text {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 15px;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
  }

  &__arrow {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}

@include respond-up('large') {
  .capper-info-bottom {

    &__list {
      padding: 33px 0;
    }

    &__item {
      flex: 0 0 25%;
    }

    &__name {
      font-size: 15px;
      line-height: 18/15*1em;
    }

    &__value {
      margin-top: 27px;
    }

    &__value-icon {
      width: 48px;
      height: 52px;
      flex: 0 0 52px;
    }

    &__value-text {
      font-size: 20px;
      line-height: 24/20px;
      flex: 0 0 52px;
    }

    &__link {
      //border-radius: 0 0 0 6px;
    }
  }
}

@include respond-up('medium') {
  .capper-info-bottom {

  }
}

@include respond('medium') {
  .capper-info-bottom {

  }
}

@include respond-down('medium') {
  .capper-info-bottom {
    &__list {
      flex-wrap: wrap;
    }

    &__item {
      flex: 0 0 50%;
      padding: 25px var(--grid-spacer);

      &:nth-child(n + 3) {
        border-top: 1px solid var(--primary-16);
      }
      &:nth-child(odd) {
        border-right: 1px solid var(--primary-16);
      }
    }

    &__name {
      font-size: 14px;
      line-height: 17/14*1em;
    }

    &__value {
      margin-top: 13px;
    }

    &__value-icon {
      width: 36px;
      height: 37px;
      flex: 0 0 37px;
    }

    &__value-text {
      font-size: 16px;
      line-height: 20/16px;
      flex: 0 0 37px;
    }
  }
}

@include respond-down('small') {
  .capper-info-bottom {

  }
}