.capper-columns {

}

@include respond-up('large') {
  .capper-columns {
    display: grid;
    grid-gap: var(--grid-gap);
    background-color: var(--color-gray-bg);
    border-bottom: 1px solid rgba(24, 39, 45, 0.5);

    grid-template-columns: repeat(9, 1fr);
    //grid-template-columns:
    //          calc(var(--grid-column) - 10px)
    //          repeat(5, 1fr)
    //          calc(var(--grid-column2) - var(--grid-gap))
    //          23px
    //          95px;

    &__column {
      display: flex;
      align-items: center;

      &_name {
        grid-column: 1/4;
        padding-left: 10px;
      }

      &_rating {
        grid-column: 4/5;
      }

      &_reviews {
        grid-column: 5/6;
      }

      &_votes {
        grid-column: 6/7;
      }

      &_sport {
        grid-column: 7/10;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      padding: 15px 0;

      &._active {
        svg path{
          stroke: var(--color-bg);
        }
      }

      &._up {
        svg {
          transform: rotate(-180deg);
        }
      }
    }

    &__name {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: var(--color-bg);
    }

    &__arrow {
      margin-left: 3px;
      display: flex;
      align-items: center;

      svg {
        display: flex;
        align-items: center;
        transition: {
          property: transform;
          duration: var(--default-transition-timing);
        };

        path {
          stroke: rgba(12, 27, 34, 0.2);
          transition: {
            property: stroke;
            duration: var(--default-transition-timing);
          };
        }
      }
    }
  }
}

@include respond-up('medium') {
  .capper-columns {

  }
}

@include respond('medium') {
  .capper-columns {

  }
}

@include respond-down('medium') {
  .capper-columns {
    display: none;
  }
}

@include respond-down('small') {
  .capper-columns {

  }
}