.rating-page {
  &__title {
    color: white;
    font-style: italic;
    font-weight: bold;
  }

  &__cappers-filterable {

  }

  &__article {
    background-color: white;
    border-radius: 6px 6px 0 0;
    padding: var(--grid-spacer);
  }

  &__image-wrap {
    position: relative;
    border-radius: 6px;
    padding-bottom: 50%;
    width: 100%;
  }

  &__picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-fit: cover;
    object-position: center;
  }

  &__sharing-wrap {
    display: flex;
    justify-content: flex-end;
    background-color: white;
    padding: 10px var(--grid-spacer);
    border-radius: 0 0 6px 6px;
    border-top: 1px solid var(--primary-16);
  }
}

@include respond-up('large') {
  .rating-page {
    &__article, &__cappers-filterable, &__image-wrap, &__scammers {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .rating-page {

  }
}

@include respond('medium') {
  .rating-page {

  }
}

@include respond-down('medium') {
  .rating-page {
    &__article, &__cappers-filterable, &__image-wrap, &__scammers {
      margin-top: 30px;
    }

    &__article {
    }

    &__article-wrap {
      margin: 0 calc(var(--grid-spacer) * -1);
    }

    &__image-wrap {
      margin-bottom: var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .rating-page {

  }
}