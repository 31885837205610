.capper-features {
  &__title {
    color: white;
    font-weight: 800;
    font-style: italic;
  }

  &__item {
    border-radius: 6px;

    &_green {
      background: rgba(163, 255, 180, 0.2);
      border: 1px solid #56BE6A;
    }

    &_black {
      border: 2px solid var(--color-gray-text);
    }
  }

  &__name {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    font-size: 14px;
    line-height: 17/14*1em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: white;
  }

  &__description {
    font-weight: 400;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: var(--color-gray-text);
  }
}

@include respond-up('large') {
  .capper-features {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 0 var(--grid-spacer);
    padding-top: 44px;

    &__title {
      grid-column: 1/10;
      margin-bottom: 43px;
      font-size: 35px;
      line-height: 43/35*1em;
    }

    &__name {
      margin-bottom: 11px;
    }

    &__list {
      grid-column: 1/9;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--grid-gap);
    }

    &__item {
      padding: 20px var(--grid-gap) 18px;
    }

    &__description {
      font-size: 15px;
      line-height: 18/15*1em;
    }
  }
}

@include respond-up('medium') {
  .capper-features {

  }
}

@include respond('medium') {
  .capper-features {

  }
}

@include respond-down('medium') {
  .capper-features {
    padding-top: 44px;

    &__title {
      margin-bottom: 26px;
      font-size: 24px;
      line-height: 29/24*1em;
    }

    &__item {
      padding: 15px var(--grid-spacer);

      &:nth-child(n + 2) {
        margin-top: 20px;
      }
    }

    &__name {
      margin-bottom: 10px;
    }

    &__description {
      font-size: 14px;
      line-height: 17/14*1em;
    }
  }
}

@include respond-down('small') {
  .capper-features {

  }
}