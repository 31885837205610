@font-face {
    font-family: 'Effra';
    src: url('Effra-Bold.eot');
    src: url('Effra-Bold.eot?#iefix') format('embedded-opentype'),
        url('Effra-Bold.woff2') format('woff2'),
        url('Effra-Bold.woff') format('woff'),
        url('Effra-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*@font-face {*/
/*    font-family: 'Effra';*/
/*    src: url('Effra-Italic.eot');*/
/*    src: url('Effra-Italic.eot?#iefix') format('embedded-opentype'),*/
/*        url('Effra-Italic.woff2') format('woff2'),*/
/*        url('Effra-Italic.woff') format('woff'),*/
/*        url('Effra-Italic.ttf') format('truetype');*/
/*    font-weight: normal;*/
/*    font-style: italic;*/
/*    font-display: swap;*/
/*}*/

@font-face {
    font-family: 'Effra';
    src: url('Effra-Regular.eot');
    src: url('Effra-Regular.eot?#iefix') format('embedded-opentype'),
        url('Effra-Regular.woff2') format('woff2'),
        url('Effra-Regular.woff') format('woff'),
        url('Effra-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*@font-face {*/
/*    font-family: 'Effra';*/
/*    src: url('Effra-Light.eot');*/
/*    src: url('Effra-Light.eot?#iefix') format('embedded-opentype'),*/
/*        url('Effra-Light.woff2') format('woff2'),*/
/*        url('Effra-Light.woff') format('woff'),*/
/*        url('Effra-Light.ttf') format('truetype');*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*    font-display: swap;*/
/*}*/

@font-face {
    font-family: 'Effra';
    src: url('Effra-Medium.eot');
    src: url('Effra-Medium.eot?#iefix') format('embedded-opentype'),
        url('Effra-Medium.woff2') format('woff2'),
        url('Effra-Medium.woff') format('woff'),
        url('Effra-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}