.capper-info-side {
  display: flex;
  align-items: center;
  background-color: var(--color-gray-bg);
  justify-content: space-between;


  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__value-wrap {
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__value {
    font-weight: 700;
    letter-spacing: 0.05em;
  }

  &__label {
    font-weight: 700;
    letter-spacing: 0.05em;
  }
}

@include respond-up('large') {
  .capper-info-side {
    flex-direction: column;
    padding: 19px 10px 24px;
    //border-radius: 0 6px 0 0;

    &__icon {
      margin-right: 10px;
      svg {
        width: 40px;
        height: 40px;
      }

      &_reviews {
        padding-top: 10px;
      }
    }


    &__value {
      font-size: 25px;
      line-height: 30/25*1em;
    }

    &__label {
      font-size: 15px;
      line-height: 18/15*1em;
      margin-top: 8px;
    }
  }
}

@include respond-up('medium') {
  .capper-info-side {

  }
}

@include respond('medium') {
  .capper-info-side {

  }
}

@include respond-down('medium') {
  .capper-info-side {
    padding: 26px var(--grid-spacer) 26px;

    &__icon {
      margin-right: 6px;

      svg {
        width: 30px;
        height: 30px;
      }

      &_reviews {
        padding-top: 5px;
      }
    }

    &__block {
      height: 100%;
      justify-content: space-between;

      &_ratings {
        order: 3;
      }

      &_reviews {
        order: 9;

      }

      &_votes {
        order: 6;
      }
    }

    &__value {
      font-size: 20px;
      line-height: 24/20*1em;
    }

    &__label {
      font-size: 14px;
      line-height: 17/14*1em;
      margin-top: 7px;
    }
  }
}

@include respond-down('small') {
  .capper-info-side {

  }
}