.terms-page {
  &__title {
    color: white;
    margin-bottom: var(--grid-spacer);
    font-style: italic;
  }

  &__char-list {

  }

  &__char-item {
    margin-bottom: 20px;
  }

  &__char {
    //color: var(--primary-color);
    color: white;
    font-size: 23px;
    font-weight: 700;
    border-bottom: 1px solid var(--primary-16);
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  &__term-list {

  }

  &__term-item {
    padding-bottom: 10px;

    &._opened {
      .terms-page {
        &__name-icon svg{
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__name {
  }

  &__name-text {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 400;
  }

  &__name-icon {
    svg {
      transition: {
        property: transform;
        duration: var(--default-transition-timing);
      };
    }
  }

  &__description-wrap {
    overflow: hidden;
    height: 0;
    transition: {
      property: height;
      duration: var(--default-transition-timing);
    };
  }

  &__description {
    color: white;
    padding: 5px 0 15px;
    font-size: 16px;
    letter-spacing: 0.05em;

    a {
      text-decoration: underline;
      color: var(--primary-color);
      transition: {
        property: color;
        duration: var(--default-transition-timing);
      };
      &:hover {
        color: var(--primary-16-on-white);
      }
    }
  }

  &__hide {
    overflow: hidden;
    height: 0;
  }

  &__id {
    color: var(--color-yellow);
  }
}

@include respond-up('large') {
  .terms-page {

  }
}

@include respond-up('medium') {
  .terms-page {

  }
}

@include respond('medium') {
  .terms-page {

  }
}

@include respond-down('medium') {
  .terms-page {

  }
}

@include respond-down('small') {
  .terms-page {

  }
}