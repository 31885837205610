.side-main-top {
  border-radius: 6px;
  margin-bottom: var(--grid-gap);

  &__title {
    font-weight: 800;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 10px;
    color: white;
    font-style: italic;
  }

  &__list {
    border-radius: 6px;
  }

  &__number-block {
    display: flex;
    align-items: center;
    position: relative;
    background: var(--gradient-green);
    border-radius: 6px;
  }

  &__wrap {
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    &._opened {
      .side-main-top {
        &__more {
          display: none;
        }
      }
    }
  }

  &__list-wrap {
    overflow: hidden;
    transition: {
      property: height;
      duration: var(--default-transition-timing);
      timing-function: var(--default-transition-function);
    };
  }

  &__more {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 5px;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 56px;
      background: linear-gradient(0deg, white 50%, rgba(255, 255, 255, 0) 100%);
    }

    &:hover {
      .side-main-top {
        &__more-text {
          color: var(--color-telegram);
        }

        &__more-arrow {
          svg path {
            stroke: var(--color-telegram);
          };
        }
      }
    }
  }

  &__more-text {
    position: relative;
    z-index: 5;
    color: var(--primary-color);
    font-weight: 500;
    font-size: 14px;
    transition: {
      property: color;
      duration: var(--default-transition-timing);
    };
  }

  &__more-arrow {
    position: relative;
    z-index: 5;

    svg {
      margin-left: 5px;

      path {
        stroke: var(--primary-color);
        transition: {
          property: stroke;
          duration: var(--default-transition-timing);
        };
      }
    }
  }
}

@include respond-up('large') {
  .side-main-top {
    background: var(--primary-gradient);
    padding: 11px 3px 3px;

    &._is-accordion {
      .side-main-top__list-wrap {
        height: 422px;
      }
    }

    &__title {
      font-size: 17px;
      line-height: 20/17*1em;
    }
  }
}

@include respond-up('medium') {
  .side-main-top {

  }
}

@include respond('medium') {
  .side-main-top {

  }
}

@include respond-down('medium') {
  .side-main-top {
    background: var(--gradient-green);
    padding: 11px 10px 10px;

    &._is-accordion {
      .side-main-top__list-wrap {
        height: 262px;
      }
    }

    &__title {
      font-size: 19px;
      line-height: 23/19*1em;
    }
  }
}

@include respond-down('small') {
  .side-main-top {

  }
}