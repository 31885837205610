.side-tops {
  &__top {
    margin-bottom: var(--grid-gap);
  }

  &__top-name {
    display: flex;
    align-items: center;
    background: var(--primary-gradient);
    padding: 14px 10px;
  }

  &__top-name-text {
    color: white;
    font-weight: 800;
    font-size: 16px;
    line-height: 20/16*1em;
    letter-spacing: 0.05em;
    font-family: var(--h-font);
    font-style: italic;
  }

  &__list {
    background-color: var(--primary-16);
    border-radius: 0 0 6px 6px;
  }

  &__item {
    &:not(:first-child) {
      border-top: 1px solid var(--primary-color);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 6px 7px;
  }

  &__number {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
    color: var(--primary-color);
    background-color: var(--primary-16);
    border-radius: 40px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22/18*1em;
  }

  &__name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
    color: var(--primary-color);
    flex: 1 1 auto;
  }

  &__arrow {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    background-color: var(--primary-16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;

    svg {
      transform: rotate(-90deg);
    }
  }
}

@include respond-up('large') {
  .side-tops {
    &__top-name {
      pointer-events: none;
      justify-content: center;
      border-radius: 6px 6px 0 0;
    }

    &__top-arrow {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .side-tops {

  }
}

@include respond('medium') {
  .side-tops {

  }
}

@include respond-down('medium') {
  .side-tops {
    &._accordion {
      .side-tops {
        &__top-name {
          justify-content: space-between;
          border-radius: 6px;
          transition: {
            property: border-radius;
            duration: var(--default-transition-timing);
          };
        }

        &__top-arrow {

          svg {
            display: flex;
            transition: {
              property: transform;
              duration: var(--default-transition-timing);
              timing-function: var(--default-transition-function);
            };

            path {
              stroke: white;
            }
          }
        }

        &__list-wrap {
          height: 0;
          overflow: hidden;
          transition: {
            property: height;
            duration: var(--default-transition-timing);
            timing-function: var(--default-transition-function);
          };
        }

        &__top {
          &._opened {
            .side-tops {
              &__top-name {
                border-radius: 6px 6px 0 0;
              }
              &__top-arrow {
                svg {
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }
      }
    }

    &._static {
      .side-tops {
        &__top-name {
          justify-content: center;
          border-radius: 6px 6px 0 0;
        }
        &__top-arrow {
          display: none;

        }
      }
    }
  }
}

@include respond-down('small') {
  .side-tops {

  }
}