.hamburger {
  &._opened {
    .hamburger {
      &__line {
        &_top {
          transform: rotate(45deg) translate3d(3px, 3px, 0);
        }
        &_middle {
          transform: translateX(100%);
          opacity: 0;
        }
        &_bottom {
          transform: rotate(-45deg) translate3d(4px, -4px, 0px)
        }
      }
    }
  }

  &__circle {
    background-color: var(--primary-16);
    position: relative;
    height: 37px;
    width: 37px;
    border-radius: 100px;
  }

  &__line {
    background-color: var(--primary-color);
    height: 2px;
    width: 20px;
    position: absolute;
    left: calc(50% - 10px);
    will-change: transform;
    transition: {
      property: transform, top, opacity;
      duration: var(--default-transition-timing);
    };

    &_top {
      top: calc(50% - 6px);
    }

    &_middle {
      top: calc(50% - 1px);
    }

    &_bottom {
      top: calc(50% + 4px);
    }
  }
}

@include respond-up('large') {
  .hamburger {

  }
}

@include respond-up('medium') {
  .hamburger {

  }
}

@include respond('medium') {
  .hamburger {

  }
}

@include respond-down('medium') {
  .hamburger {

  }
}

@include respond-down('small') {
  .hamburger {

  }
}