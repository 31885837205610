body {
  font-size: var(--body-font-size);
  font-family: var(--font);
  line-height: var(--body-line-height);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
  letter-spacing: var(--letter-spacing);
}

input, button, form {
  font-family: var(--font);
}