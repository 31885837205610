.capper-page {
  &__main-content {
    padding-bottom: 40px;
  }

  &__form-wrap {

  }

  &__description {
  }

  &__description-title {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: white;
  }

  &__after-article-block-list {
    border-top: 1px solid var(--primary-16);
  }

  &__sharing {
    padding: 0 var(--grid-spacer);
  }
}

@include respond-up('large') {
  .capper-page {
    &__form-wrap {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-gap: var(--grid-gap);
      padding-top: 60px;
    }

    &__review-form {
      grid-column: 2/9;
    }

    &__description-title {
      font-size: 35px;
      line-height: 43/32*1em;
      margin: 65px 0 38px;
    }

    &__after-article-block-list {
      border-top: 1px solid var(--primary-16);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: -1px;
    }

    &__article-link {
      flex: 1 1 auto;
    }

    &__sharing {
      border-left: 1px solid var(--primary-16);
      height: 39px;
    }
  }
}

@include respond-up('medium') {
  .capper-page {

  }
}

@include respond('medium') {
  .capper-page {

  }
}

@include respond-down('medium') {
  .capper-page {
    padding-top: 0;

    &__form-wrap {
      padding-top: 40px;
    }

    &__description-title {
      font-size: 24px;
      line-height: 29/24*1em;
      margin: 40px 0 21px;
    }

    &__sharing {
      border-top: 1px solid var(--primary-16);
      justify-content: flex-end;
      padding: 10px var(--grid-spacer);
    }

    &__after-article-block-list {
      //margin: -1px calc(var(--grid-spacer) * -1) 0;
    }
  }
}

@include respond-down('small') {
  .capper-page {

  }
}