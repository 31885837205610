.header-content {
  &__menu-wrap {

  }

  &__menu {
  }

  &__item {
  }

  &__link {

  }

}

@include respond-up('large') {
  .header-content {
    height: 100%;

    &__search-item, &__contact-wrap {
      display: none;
    }

    &__menu-wrap {
      height: 100%;
    }

    &__menu {
      display: flex;
      height: 100%;
      margin-right: -23px;
    }

    &__item {
      height: 100%;
      position: relative;

      &:hover {
        .header-content {
          &__link-text {
            color: var(--primary-color);
          }
          &__arrow svg path {
            stroke: var(--primary-color);
          }
          &__child-wrap {
            opacity: 1;
            pointer-events: all;
          }
          &__child-list {
            transform: translateY(0);
          }
        }
      }
    }

    &__link {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 23px;
    }

    &__link-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 15/12*1em;
      text-transform: uppercase;
      color: white;
      transition: {
        property: color;
        duration: var(--default-transition-timing);
      };
    }

    &__arrow {
      display: flex;
      align-items: center;
      margin-left: 7px;

      svg path{
        stroke: white;
        transition: {
          property: stroke;
          duration: var(--default-transition-timing);
        };
      }
    }

    &__link-icon {
      display: none;
    }

    &__child-wrap {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: var(--color-bg-light);
      border-top: 1px solid var(--primary-16);
      pointer-events: none;
      opacity: 0;
      overflow: hidden;
      transition: {
        property: opacity;
        duration: var(--default-transition-timing);
      };
    }

    &__child-list {
      display: grid;
      grid-gap: 0 var(--grid-gap);
      padding: 10px var(--grid-spacer) 30px;
      transform: translateY(-10px);
      transition: {
        property: transform;
        duration: var(--default-transition-timing);
      };

      &._long {
        grid-template-columns: auto auto;
      }

      &._short {
        grid-template-columns: auto;
      }
    }

    &__child-item {
      display: flex;

      &:nth-child(n + 3) {
        border-top: 1px solid var(--primary-16);
      }
    }

    &__child-link {
      flex: 1 1 auto;
      display: flex;
      color: white;
      text-align: right;
      padding: 10px 20px 10px 0;
      white-space: nowrap;
      min-width: 270px;
      transition: {
        property: color;
        duration: var(--default-transition-timing);
      };

      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

@include respond-up('medium') {
  .header-content {

  }
}

@include respond('medium') {
  .header-content {
  }
}

@include respond-down('medium') {
  .header-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__menu-wrap {
      flex: 1 1 auto;
      overflow: hidden;
    }

    &__menu {
      padding: var(--grid-spacer) calc(var(--grid-spacer) + 20px) calc(var(--grid-spacer) + 20px) var(--grid-spacer);
      overflow: scroll;
      height: calc(100% + 20px);
      width: calc(100% + 20px);
      display: flex;
      flex-direction: column;
    }

    &__item {
      flex: 0 0 auto;
      margin-bottom: 10px;
      background-color: var(--primary-16);
      border-radius: 3px;
      overflow: hidden;
      transition: {
        property: transform, opacity;
        duration: .2s;
        timing-function: var(--default-transition-function);
      };
      opacity: 0;
      transform: translateX(50%);

      @for $i from 1 through 15 {
        &:nth-child(#{$i}) {
          transition-delay: calc(#{$i} * .1s);
        }
      }

      &:first-child {
        .header-content {
          &__link {
            background: var(--gradient-green);
          }
          &__link-text {
            text-transform: uppercase;
            font-weight: bold;
            color: white;
          }
        }
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--primary-color);
      padding: 13px 17px;
    }

    &__link-text {
      letter-spacing: 0.05em;
      line-height: 19/16*1em;
      font-weight: 500;
      font-size: 16px;
      flex: 1 1 auto;
    }

    &__link-icon {
      display: flex;
      align-items: center;
      flex: 0 0 23px;
      margin-right: 10px;

    }

    &__child-wrap {
      height: 0;
      overflow: hidden;
      transition: {
        property: height;
        duration: var(--default-transition-timing);
        timing-function: var(--default-transition-function);
      };
    }

    &__child-list {

    }

    &__child-item {

    }

    &__child-link {
      display: flex;
      align-items: center;
      border-top: 1px solid var(--primary-16);
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      //letter-spacing: 0.05em;
      color: white;
      padding: 12px 24px;
    }

    &__contact-wrap {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      padding: 17px var(--grid-spacer);
      background-color: var(--primary-16);
      border-top: 1px solid var(--primary-16);
      transform: translateY(100%);
      transition: {
        property: transform;
        duration: var(--default-transition-timing);
        timing-function: var(--default-transition-function);
        delay: .4s;
      };
    }

    &__contact-label {
      font-weight: 500;
      font-size: 15px;
      line-height: 18/15*1em;
      color: white;
      margin-right: 10px;
    }

    &__contact-link {
      display: flex;
      align-items: center;
    }

    &__contact-icon {
      display: flex;
      align-items: center;
      margin-right: 5px;

      svg {
        margin-left: -3px;
      }
    }

    &__contact-name {
      font-weight: 500;
      font-size: 15px;
      line-height: 18/15*1em;
      color: var(--color-telegram);
    }

    &__search-item {
      flex: 1 1 auto;
      display: flex;
      align-items: flex-end;
      opacity: 0;
      transition: {
        property: opacity;
        duration: var(--default-transition-timing);
        delay: .4s;
      };
    }

    &__search {
      width: 100%;
    }
  }

  ._menu-opened {
    .header-content {
      &__contact-wrap {
        transform: translateY(0);
      }

      &__item {
        transform: translateX(0);
        opacity: 1;
      }

      &__search-item {
        opacity: 1;
      }
    }
  }
}

@include respond-down('small') {
  .header-content {

  }
}