.review-modal-form {
  &__title {
    margin-bottom: var(--grid-spacer);
    font-style: italic;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
  }

  &__field {
    input[type=text], textarea {
      background: white;
      border: 1px solid #C7C7C7;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
    }

    input[type=text] {
      width: 280px;
    }

    textarea {
      height: 174px;
    }
  }

  &__button-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    width: 200px;
  }
}

@include respond-up('large') {
  .review-modal-form {

  }
}

@include respond-down('medium') {
  .review-modal-form {

  }
}