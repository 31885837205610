:root {

}

@include respond-up('large') {
  :root {
    --header-height: 55px;

  }
}

@include respond-up('medium') {
  :root {

  }
}

@include respond('medium') {
  :root {

  }
}

@include respond-down('medium') {
  :root {
    --header-height: 55px;

  }
}

@include respond-down('small') {
  :root {

  }
}