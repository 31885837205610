.cappers-filterable {
  position: relative;

  &__content {

  }
  &__preloader {
    opacity: 0;
    pointer-events: none;
  }

  &._loading, &._updating {
    .cappers-filterable {
      &__preloader {
        opacity: 1;
        pointer-events: all;
      }
    }

    .capper-filterable-list {
      &__empty {
        opacity: 0;
      }
    }
  }

  &__list {
    overflow: hidden;
  }

  &._updating {

  }
}

@include respond-up('large') {
  .cappers-filterable {
    --list-height: 520px;
  }
}

@include respond-up('medium') {
  .cappers-filterable {

  }
}

@include respond('medium') {
  .cappers-filterable {

  }
}

@include respond-down('medium') {
  .cappers-filterable {
    --list-height: 665px;

    &._loading {
      .capper-filterable-list {
        &__empty {
          height: var(--list-height);
        }
      }
    }
  }
}

@include respond-down('small') {
  .cappers-filterable {

  }
}

@keyframes preloader-1 {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}