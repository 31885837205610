.checkbox-list {
  display: flex;

  input {
    display: none;

    + label {
      position: relative;
      padding-left: 22px;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 17px;
        height: 17px;
        border-radius: 3px;
        border: 1px solid var(--primary-color);
        z-index: 10;
        transition: {
          property: background-color;
          duration: var(--default-transition-timing);
        };
      }

      &::after {
        content: '';
        position: absolute;
        left: 1px;
        width: 17px;
        height: 17px;
        background: {
          image: url('/static/images/svg/checkbox.svg');
          position: center;
          repeat: no-repeat;
          size: 100%;
        };
        transition: {
          property: opacity, transform;
          duration: var(--default-transition-timing);
        };
        opacity: 0;
        transform: scale(.5);
        z-index: 20;
      }
    }

    &:checked + label {
      &::before {
        background-color: var(--primary-color);
      }
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }


  &_iconable {
    .checkbox-list {
      &__label {
        &::before, &::after {
          display: none;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: var(--primary-16);
        padding: 5px 10px;
        cursor: pointer;
        transition: {
          property: background-color;
          duration: var(--default-transition-timing);
        };
      }

      &__icon {
        flex: 0 0 17px;
        height: 17px;
        width: 17px;
        margin-right: 5px;

        svg {
          width: 100%;
          height: 100%;

          path {
            stroke: var(--primary-color);
            transition: {
              property: fill, stroke;
              duration: var(--default-transition-timing);
            };
          }
        }
      }

      &__name {
        color: var(--primary-color);
        font-weight: 500;
        font-size: 14px;
        line-height: 17/14*1em;
        letter-spacing: 0.05em;
        transition: {
          property: color;
          duration: var(--default-transition-timing);
        };
      }


      &__item {
        input:checked + label {
          background-color: var(--primary-color);

          .checkbox-list {

            &__name {
              color: white;
            }

            &__icon {
              svg path {
                &[fill] {
                  fill: white;
                }
                &[stroke] {
                  stroke: white;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .checkbox-list {
    align-items: center;
    margin: 0 -5px;

    li {
      margin: 0 5px;
    }
  }
}

@include respond-up('medium') {
  .checkbox-list {

  }
}

@include respond('medium') {
  .checkbox-list {

  }
}

@include respond-down('medium') {
  .checkbox-list {
    flex-direction: column;
    padding: 5px 0;

    li {
      margin-bottom: 10px;
    }

    &_iconable {
      flex-direction: row;
      margin: 0 -5px;
      flex-wrap: wrap;

      .checkbox-list {
        &__item {
          margin: 0 5px 10px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .checkbox-list {

  }
}