.article-content {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;

  &__block-list {
    padding-bottom: 36px;
  }

  &__sharing-wrap {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--primary-16);
    padding: 10px var(--grid-spacer);
  }
}

@include respond-up('large') {
  .article-content {

    &__block-item {
      padding-top: 36px;
    }
  }
}

@include respond-up('medium') {
  .article-content {

  }
}

@include respond('medium') {
  .article-content {

  }
}

@include respond-down('medium') {
  .article-content {
    margin: 0 calc(var(--grid-spacer) * -1);
    &__block-list {
    }

    &__block-item {
      padding-top: 24px;
    }
  }
}

@include respond-down('small') {
  .article-content {

  }
}