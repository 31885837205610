.scammer-list {
  position: relative;

  &._loading {
    .scammer-list__preloader {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  &__top {
    background: var(--primary-gradient);
    border-radius: 6px 6px 0 0;
    height: 37px;
  }

  &__list {
    height: calc(var(--item-height) * var(--count-items));
  }

  &__item {
    height: var(--item-height);
  }

  &__pagination {

  }

  &__scammer {

  }

  &__preloader {
    opacity: 0;
    pointer-events: none;
  }
}

@include respond-up('large') {
  .scammer-list {
    --item-height: 52px;
    --count-items: 10;
  }
}

@include respond-up('medium') {
  .scammer-list {

  }
}

@include respond('medium') {
  .scammer-list {

  }
}

@include respond-down('medium') {
  .scammer-list {
    --item-height: 90px;
    --count-items: 5;
  }
}

@include respond-down('small') {
  .scammer-list {

  }
}