.bookie-page {
  &__h1 {
    color: white;
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.05em;
  }

  &__heading {
    margin-bottom: var(--grid-gap);
    overflow: hidden;
    border-radius: 6px;
  }

  &__bookie {
    background: var(--primary-gradient);
    display: flex;
    align-items: center;
  }

  &__content {
    background-color: white;
    border-radius: 6px;
    overflow: hidden;
  }

  &__article {
    padding: 10px var(--grid-spacer);
  }

  &__sharing-wrap {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--primary-16);
    padding: 10px var(--grid-spacer);
  }

  &__image-wrap {
    background-color: black;
    border-radius: 100px;
    overflow: hidden;
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-position: center;
    object-fit: cover;
  }

  &__more-block {
    overflow: hidden;
    height: 0;
    transition: {
      property: height;
      duration: var(--default-transition-timing);
    };
  }

  &__more-link {
    display: inline-block;
    padding-left: var(--grid-spacer);
    color: var(--primary-color);
  }

  &__more-element._opened {
    .bookie-page__more-link {
      display: none;
    }
  }
}

@include respond-up('large') {
  .bookie-page {
    &__h1 {
    }

    &__bookie {
      padding: 15px var(--grid-spacer);
    }

    &__content {
      padding-top: 36px;
    }

    &__image-wrap {
      border: 8px solid white;
      flex: 0 0 130px;
      width: 130px;
      height: 130px;
      margin-right: 28px;
    }

  }
}


@include respond-down('medium') {
  .bookie-page {
    &__bookie {
      padding: 10px var(--grid-spacer);
    }

    &__image-wrap {
      border: 4px solid white;
      flex: 0 0 80px;
      width: 80px;
      height: 80px;
      margin-right: var(--grid-gap);
    }

    &__content {
      margin: 0 calc(var(--grid-spacer) * -1);
      padding-top: 24px;
    }
  }
}
