.search-item {
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;

  &._even {
    background-color: var(--color-gray-bg);
  }

  &._odd {
    background-color: white;
  }


  &__image-block {
    position: relative;
    width: 100px;
    height: 26px;
    border-radius: 6px;
    flex: 0 0 60px;
    margin-right: 5px;

    &._blue {
      background: var(--primary-gradient);
    }

    &._red {
      background: var(--red-gradient);
    }
  }

  &__image-wrap {
    position: absolute;
    background-color: black;
    border: 3px solid white;
    border-radius: 100px;
    overflow: hidden;
    width: 46px;
    height: 46px;
    top: calc(50% - 23px);
    left: calc(50% - 23px);
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-position: center;
    object-fit: cover;
  }

  &__name {
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.05em;
  }
}

@include respond-up('large') {
  .search-item {

  }
}

@include respond-up('medium') {
  .search-item {

  }
}

@include respond('medium') {
  .search-item {

  }
}

@include respond-down('medium') {
  .search-item {

  }
}

@include respond-down('small') {
  .search-item {

  }
}