h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
}

h1, h2, h3, .h1, .h2, .h3 {
  font-weight: bold;
}

h4, h5, h6, .h4, .h5, .h6 {
  font-weight: 500;
}

@include respond-up('large') {
  h1, .h1 {
    --h-size: 40px;
  }

  h2, .h2 {
    --h-size: 35px;
  }

  h3, .h3 {
    --h-size: 26px;
  }

  h4, .h4 {
    --h-size: 22px;
  }

  h6, .h6 {
    --h-size: 17px;
  }
}


@include respond-down('medium') {
  h1, .h1 {
    --h-size: 28px;
  }

  h2, .h2 {
    --h-size: 24px;
  }

  h3, .h3 {
    --h-size: 20px;
    line-height: 135%;
  }

  h4, .h4 {
    --h-size: 18px;
  }

  h5, h6, .h5, .h6 {
    --h-size: 16px;
    line-height: 140%;
  }
}
