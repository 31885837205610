.index-main-top {
  background: var(--primary-gradient);
  border-radius: 6px;

  &__title {
    font-weight: 800;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 10px;
    color: white;
    font-style: italic;
  }

  &__list {
    background-color: white;
    border-radius: 6px;
  }
}

@include respond-up('large') {
  .index-main-top {
    padding: 13px 11px 11px;

    &__title {
      font-size: 22px;
      line-height: 27/22*1em;
      text-transform: uppercase;
    }

  }
}

@include respond-up('medium') {
  .index-main-top {

  }
}

@include respond('medium') {
  .index-main-top {

  }
}

@include respond-down('medium') {
  .index-main-top {
    padding: 11px 10px 10px;

    &__title {
      font-size: 19px;
      line-height: 23/19*1em;
    }
  }
}

@include respond-down('small') {
  .index-main-top {

  }
}