.error-page {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px var(--grid-spacer);
  }

  &__code {
    font-family: var(--h-font);
    color: var(--primary-color);
    text-align: center;
  }

  &__message {
    color: var(--color-gray-text);
    text-align: center;
    font-size: 16px;

    a {
      color: var(--primary-color);
    }
  }
}

@include respond-up('large') {
  .error-page {
    &__code {
      font-size: 80px;
      margin-bottom: 25px;
    }
  }
}

@include respond-down('medium') {
  .error-page {
    &__code {
      font-size: 45px;
      margin-bottom: 20px;
    }
  }
}