.scammer-info {
  &__header {
    background: var(--red-gradient);
  }

  &__scammer {
    display: flex;
    align-items: center;
  }

  &__image-wrap {
    background-color: black;
    border-radius: 100px;
    overflow: hidden;
  }

  &__name {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.05em;
    color: white;
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-position: center;
    object-fit: cover;
  }

  &__votes-block {
    background-color: white;
    display: flex;
  }

  &__votes {
    display: flex;
  }

  &__votes-icon {
    margin-right: 10px;
    svg {
      transform: rotate(180deg);
      path {
        &[stroke] {
          stroke: var(--color-red);
        }
        &[fill] {
          fill: var(--color-red);
        }
      }
    }
  }

  &__votes-value {
    font-weight: 700;
    font-size: 20px;
    line-height: 24/20*1em;
    letter-spacing: 0.05em;
  }

  &__votes-name {
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  &__actions {
    background-color: var(--primary-16);
  }

  &__link {
    display: flex;
    justify-content: center;
    padding: 9px 0 11px;
  }

  &__link-text {
    color: var(--primary-color);
    font-weight: 400;
    font-size: 15px;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
  }

  &__arrow {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  &__vote-link {
    background-color: var(--primary-16);
    color: var(--primary-color);
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.05em;

    &._already {
      color: var(--color-gray-text);
      background-color: var(--color-bg-light);
      pointer-events: none;
    }
  }
}

@include respond-up('large') {
  .scammer-info {
    &__header, &__actions {
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: repeat(9, 1fr);
      overflow: hidden;
    }

    &__header {
      border-radius: 6px 6px 0 0;
    }

    &__actions {
      border-radius: 0 0 6px 6px;
    }

    &__scammer {
      padding: 15px var(--grid-spacer);
      grid-column: 1/8;
    }

    &__image-wrap {
      border: 8px solid white;
      flex: 0 0 130px;
      width: 130px;
      height: 130px;
      margin-right: 28px;
    }

    &__name {
      font-size: 40px;
      line-height: 49/40;
    }

    &__votes-block {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 10px var(--grid-spacer);
      grid-column: 8/10;
    }

    &__votes {
    }

    &__votes-name {
      font-size: 15px;
      line-height: 18/15*1em;
    }

    &__link {
      grid-column: 1/8;
    }

    &__vote-link {
      grid-column: 8/10;
    }
  }
}

@include respond-up('medium') {
  .scammer-info {

  }
}

@include respond('medium') {
  .scammer-info {

  }
}

@include respond-down('medium') {
  .scammer-info {
    margin: 0  calc(var(--grid-spacer) * -1);

    &__header {
    }

    &__scammer {
      padding: 10px var(--grid-spacer);
    }

    &__image-wrap {
      border: 4px solid white;
      flex: 0 0 80px;
      width: 80px;
      height: 80px;
      margin-right: var(--grid-gap);
    }

    &__name {
      font-size: 23px;
      line-height: 29/24*1em;
    }

    &__votes-block {
      padding: 0 var(--grid-spacer);
      justify-content: center;
    }

    &__votes {
      align-items: center;
    }

    &__votes-icon {
      padding-top: 20px;
    }

    &__votes-name {
      display: flex;
      align-items: center;
      font-size: 17px;
      line-height: 21/17*1em;
      margin-left: 7px;
    }

    &__actions {
      padding: 0 var(--grid-spacer);
      display: flex;
      //flex-direction: column;
      justify-content: space-between;
    }

    &__link {
      //order: 6;
    }

    &__vote-link {
      //order: 3;
      margin: 0  calc(var(--grid-spacer) * -1);
      padding: 0 var(--grid-spacer);
    }
  }
}

@include respond-down('small') {
  .scammer-info {

  }
}