.capper-reviews {

  &__title {
    color: white;
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.05em;
  }

  &__list-wrap {
  }

  &__more {
    display: flex;
    justify-content: center;
  }

  &__item {
    background-color: white;
    width: 100%;
    border-radius: 6px;
    padding: 20px var(--grid-spacer);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  &__name, &__child-name {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
  }

  &__name {
    flex: 0 0 100%;
  }

  &__child-name {
  }

  &__text, &__child-text {
    font-weight: 400;
    margin: 19px 0 24px;
  }

  &__text {
    flex: 0 0 485px;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__link {
    color: var(--primary-color);
    flex: 0 0 auto;
  }

  &__date, &__child-date {
    font-weight: 400;
    color: var(--color-gray-text);
    text-align: left;
    flex: 0 0 auto;
  }

  &__childrens {
    width: 100%;
  }

  &__child {
    border-top: 1px solid var(--color-gray-bg);
    padding-top: var(--grid-gap);
    margin-top: var(--grid-gap);
  }

  &__accordion-element {
    width: 100%;
  }

  &__accordion-block {
    width: 100%;
    overflow: hidden;
    height: 0;
    transition: {
      property: height;
      duration: var(--default-transition-timing);
    };
  }

  &__accordion-link {
    display: flex;
    color: var(--primary-color);
    text-decoration: underline;
    margin-top: var(--grid-gap);
    text-align: center;
    justify-content: center;
  }
}

@include respond-up('large') {
  .capper-reviews {
    padding-top: 60px;

    &__list-wrap {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-gap: 0 var(--grid-gap);
    }

    &__list, &__more {
      grid-column: 2/9;
    }

    &__item {
      margin-top: 42px;
    }

    &__name, &__child-name {
      font-size: 18px;
      line-height: 22/18px;
    }

    &__text,  {
      font-size: 16px;
      line-height: 19/16*1em;
      margin: 19px 0 24px;
    }

    &__text {
      flex: 0 0 485px;
    }

    &__date, &__link, &__child-date {
      font-size: 16px;
      line-height: 19/16*1em;
    }
  }
}

@include respond-up('medium') {
  .capper-reviews {

  }
}

@include respond('medium') {
  .capper-reviews {

  }
}

@include respond-down('medium') {
  .capper-reviews {
    padding-top: 18px;

    &__item {
      margin-top: var(--grid-gap);
    }

    &__name, &__child-name {
      font-size: 16px;
      line-height: 20/16px;
    }

    &__text, &__child-text {
      font-size: 14px;
      line-height: 17/14*1em;
      margin: 17px 0 20px;
    }

    &__text {
      flex: 0 0 100%;
    }

    &__date, &__link, &__child-date {
      font-size: 14px;
      line-height: 17/14*1em;
    }
  }
}

@include respond-down('small') {
  .capper-reviews {

  }
}