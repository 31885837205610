.colors-page {
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;

  &__bg {
    position: absolute;
    width: 50%;
    top: 0;
    height: 100%;
    z-index: 10;

    &_left {
      left: 0;
      background-color: white;
    }

    &_right {
      right: 0;
      background-color: var(--color-bg);
    }
  }

  &__block {
    height: 100px;
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    color: var(--primary-16);
    font-size: 60px;

    &:nth-child(5) { background-color: var(--primary-color); }
    &:nth-child(6) { background-color: var(--color-bg-light); }
    &:nth-child(7) { background-color: var(--color-yellow); }
    &:nth-child(8) { background-color: var(--color-gray-text); }
    &:nth-child(9) { background-color: var(--primary-16); }
    &:nth-child(10) { background-color: var(--primary-16); }
    &:nth-child(11) { background-color: var(--primary-16-on-white); }
    &:nth-child(12) { background-color: var(--primary-16-on-bg); }
  }
}

@include respond-up('large') {
  .colors-page {

  }
}

@include respond-up('medium') {
  .colors-page {

  }
}

@include respond('medium') {
  .colors-page {

  }
}

@include respond-down('medium') {
  .colors-page {

  }
}

@include respond-down('small') {
  .colors-page {

  }
}