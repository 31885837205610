.article-page {
  &__title {
    color: white;
    font-style: italic;
  }

  &__image-wrap {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    padding-bottom: 50%;
    width: 100%;
  }

  &__picture {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-fit: cover;
    object-position: center;
  }

  &__categories {

  }

  &__category-item {

  }

  &__category-link {

  }
}

@include respond-up('large') {
  .article-page {
    &__title, &__image-wrap {
      margin-bottom: 30px;
    }
  }
}

@include respond-up('medium') {
  .article-page {

  }
}

@include respond('medium') {
  .article-page {

  }
}

@include respond-down('medium') {
  .article-page {
    &__title, &__image-wrap {
      margin-bottom: 20px;
    }
  }
}

@include respond-down('small') {
  .article-page {

  }
}