.social-sharing {
  display: flex;
  align-items: center;

  &__icon {
    flex: 0 0 25px;
    width: 25px;
    height: 25px;
    margin-left: 8px;

    svg {
      width: 100%;
      height: 100%;

      path {
        stroke: var(--primary-color);
      }
    }
  }

  .ya-share2.ya-share2_inited .ya-share2__container .ya-share2__list{
    .ya-share2 {
      &__link {
        //border-radius: 1;
        border: 1px solid var(--primary-16);
        border-radius: 100px;
      }
      &__badge {
        background-color: var(--t);
      }

      &__icon {
        background-repeat: no-repeat;
        filter: invert(40%) sepia(48%) saturate(3470%) hue-rotate(192deg) brightness(113%) contrast(102%);
      }
    }
  }
}

@include respond-up('large') {
  .social-sharing {
    .ya-share2.ya-share2_inited .ya-share2__container .ya-share2__list{
      .ya-share2 {
        &__link {
          transition: {
            property: border-color;
            duration: var(--default-transition-timing);
          };

          &:hover {
            border: 1px solid var(--primary-color);
          }
        }

        &__icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .social-sharing {

  }
}

@include respond('medium') {
  .social-sharing {

  }
}

@include respond-down('medium') {
  .social-sharing {
    .ya-share2.ya-share2_inited .ya-share2__container .ya-share2__list{
      .ya-share2 {
        &__icon {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

@include respond-down('small') {
  .social-sharing {

  }
}