.heading-text-block {

  &__title {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    letter-spacing: 0.05em;
    color: white;
  }

  &__text {
    font-weight: 400;
    color: var(--color-gray-text);
  }
}

@include respond-up('large') {
  .heading-text-block {
    padding: 80px 0 21px;

    &__title {
      font-size: 35px;
      line-height: 43/35*1em;
      margin-bottom: 21px;
    }

    &__text {
      font-size: 17px;
      line-height: 27/17*1em;
    }
  }
}

@include respond-up('medium') {
  .heading-text-block {

  }
}

@include respond('medium') {
  .heading-text-block {

  }
}

@include respond-down('medium') {
  .heading-text-block {
    padding: 47px 0 11px;

    &__title {
      font-size: 24px;
      line-height: 29/24*1em;
      margin-bottom: 13px;
    }


    &__text {
      font-size: 14px;
      line-height: 156%;
    }
  }
}

@include respond-down('small') {
  .heading-text-block {

  }
}