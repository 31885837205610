.footer-menu {
  &__wrap {
  }

  &__block {
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__name-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 18/15*1em;
    color: var(--primary-color);
  }

  &__arrow {
  }

  &__list {
    padding: 5px 0;
  }

  &__item {
  }

  &__link {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 17/14px;
    color: white;
    padding: 5px 0;
  }
}

@include respond-up('large') {
  .footer-menu {
    &__wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: var(--grid-gap);
    }

    &__block {
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 var(--grid-gap);
    }

    &__arrow {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .footer-menu {

  }
}

@include respond('medium') {
  .footer-menu {

  }
}

@include respond-down('medium') {
  .footer-menu {
    &__link {
      margin-left: 25px;
    }

    &__list {
    }

    &__block {
      margin-bottom: 12px;

      &._opened {
        .footer-menu__arrow {
          transform: rotate(-180deg);
        }
      }
    }

    &__arrow {
      display: flex;
      align-items: center;
      margin-left: 4px;
      transition: {
        property: transform;
        duration: var(--default-transition-timing);
      };
    }

    &__list-wrap {
      height: 0;
      overflow: hidden;
      transition: {
        property: height;
        duration: var(--default-transition-timing);
        timing-function: var(--default-transition-function);
      };
    }
  }
}

@include respond-down('small') {
  .footer-menu {

  }
}