.capper-info-link {
  background-color: white;
  display: flex;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 9px;
  }

  &__type {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__text {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    font-size: 14px;
    line-height: 17/14*1em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--primary-color);
  }

  &__link {
    font-weight: 700;
    font-size: 15px;
    line-height: 18/15*1em;
    letter-spacing: 0.05em;
    padding: 0 var(--grid-spacer);
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .capper-info-link {
    height: 39px;
    padding-left: var(--grid-spacer);

    &__type {
      position: relative;
      padding-right: var(--grid-spacer);

      &::before {
        content: '';
        position: absolute;
        right: 0;
        width: 1px;
        height: 100%;
        top: 0;
        background-color: var(--primary-16);
      }
    }
  }
}

@include respond-down('medium') {
  .capper-info-link {
    flex-direction: column;
    padding: 14px var(--grid-spacer) 19px;

    &__link {
      padding-top: 13px;
    }
  }
}
