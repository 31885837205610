.button {
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  background-color: var(--color-yellow);
  cursor: pointer;
  text-decoration: none;
  color: var(--color-bg);
  padding: 0 15px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  height: var(--default-input-height);

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }
}