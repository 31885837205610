.articles-page {
  &__title {
    color: white;
    margin-bottom: var(--grid-spacer);
    font-style: italic;
  }

  &__list {
  }

  &__item {

  }

  &__link {
    display: flex;
    position: relative;
    padding-bottom: 50%;
    border-radius: 6px;
    overflow: hidden;
  }

  &__image-wrap {
    position: absolute;
    z-index: 20;
    background-color: var(--color-gray-text);
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    display: flex;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  &__name {
    position: absolute;
    z-index: 30;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: white;
    padding: var(--grid-spacer);
    text-align: center;
  }

  &__shadow {
    background-color: var(--color-bg);
    opacity: .7;
    position: absolute;
    z-index: 25;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  &__more {
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .articles-page {

  }
}

@include respond-up('medium') {
  .articles-page {
    &__list {
      display: grid;
      grid-gap: var(--grid-gap);
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include respond('medium') {
  .articles-page {

  }
}

@include respond-down('medium') {
  .articles-page {
    &__item {
      &:nth-child(n + 2) {
        margin-top: var(--grid-gap);
      }
    }
  }
}

@include respond-down('small') {
  .articles-page {

  }
}

