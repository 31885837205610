.capper-item {
  &._odd {
    background-color: white;
  }

  &__name {
    font-weight: 700;
  }

  &__capper {
    display: flex;
    align-items: center;
  }

  &__number-block {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 6px;
  }

  &__number {
    font-weight: 700;
    color: white;
    text-align: center;
  }

  &__image-wrap {
    position: absolute;
    background-color: black;
    border: 3px solid white;
    border-radius: 100px;
    overflow: hidden;
    width: 46px;
    height: 46px;
    top: calc(50% - 23px);
  }

  &__picture {
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__image {
    width: 100%;
    height: 100%;
    flex: 0 0 100%;
    object-position: center;
    object-fit: cover;
  }

  &__rating, &__reviews, &__votes, &__sport {
    display: flex;
    align-items: center;
  }

  &__rating-number, &__reviews-number, &__votes-number {
    font-weight: 700;
    font-size: 20px;
    line-height: 24/20*1em;
    letter-spacing: 0.05em;
    color: var(--color-bg);
  }

  &__rating-icon, &__reviews-icon, &__votes-icon {
    display: flex;
    align-items: center;
    margin-right: 7px;
    width: 20px;
    flex: 0 0 20px;
    height: 20px;
  }

  &__sport-block {
    display: flex;
    align-items: center;
    background-color: var(--primary-8);
    border-radius: 6px;
    padding: 4px 6px;
  }

  &__sport-icon {
    flex: 0 0 17px;
    width: 17px;
    height: 17px;
    margin-right: 6px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__sport-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17/14*1em;
    letter-spacing: 0.05em;
    color: var(--primary-color);
  }

  &__reviews-icon {
    padding-top: 5px;
  }

  &__button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: var(--color-yellow);
    color: var(--color-bg-light);
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: var(--h-font);
    padding: 8px 0 9px;
    width: 95px;
    font-size: 12px;
    line-height: 15/12*1em;
  }

  //&__outer {
  //  display: flex;
  //}
  //
  //&__outer-link {
  //  display: flex;
  //  align-items: center;
  //}
  //
  //&__outer-icon {
  //  display: flex;
  //  align-items: center;
  //  flex: 0 0 23px;
  //  width: 23px;
  //  height: 23px;
  //
  //  svg {
  //    width: 100%;
  //    height: 100%;
  //  }
  //}
  //
  //&__outer-text {
  //  color: var(--primary-color);
  //  text-decoration: underline;
  //}
}

@include respond-up('large') {
  .capper-item {
    //&__outer-text {
    //  font-size: 14px;
    //}
    //
    //&__outer-icon {
    //  margin-right: 8px;
    //}
  }
}

@include respond-up('medium') {
  .capper-item {

  }
}

@include respond('medium') {
  .capper-item {

  }
}

@include respond-down('medium') {
  .capper-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__capper {
      flex: 0 0 100%;
      order: 1;
      margin-bottom: 6px;
    }

    //&__outer-link {
    //}
    //
    //&__outer {
    //}
    //
    //&__outer-text {
    //  font-size: 12px;
    //}
    //
    //&__outer-icon {
    //  margin-right: 5px;
    //}
  }
}

@include respond-down('small') {
  .capper-item {

  }
}