.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-16);
  position: absolute;
  z-index: 40;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: {
    property: opacity;
    duration: var(--default-transition-timing);
  };

  &__block {
    width: 217px;
    height: 217px;
    flex: 0 0 217px;
    position: relative;
  }

  &__circle {
    position: absolute;
    border-radius: 50%;
    border: 4px solid;

    &_big {
      width: 125px;
      height: 125px;
      top: 45px;
      left: 45px;
      border-color: var(--primary-color) var(--t) var(--primary-color) var(--t);
      animation: preloader-1 2s linear infinite;
    }

    &_small {
      width: 112px;
      height: 112px;
      top: 52px;
      left: 52px;
      animation: preloader-1 2s linear infinite reverse;
      border-color: white var(--t) white var(--t);
    }
  }
}

@include respond-up('large') {
  .preloader {

  }
}

@include respond-up('medium') {
  .preloader {

  }
}

@include respond('medium') {
  .preloader {

  }
}

@include respond-down('medium') {
  .preloader {

  }
}

@include respond-down('small') {
  .preloader {

  }
}