.scammer-page {

  &__description-title {
    font-family: var(--h-font);
    font-style: italic;
    font-weight: 800;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: white;
  }

  &__description {
    margin-top: var(--grid-gap);
  }
}

@include respond-up('large') {
  .scammer-page {

  }
}

@include respond('large') {
  .scammer-page {
    &__form-wrap {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-gap: var(--grid-gap);
      padding-top: 60px;
    }

    &__review-form {
      grid-column: 2/9;
    }

    &__description-title {
      font-size: 35px;
      line-height: 43/32*1em;
      margin: 65px 0 38px;
    }
  }
}

@include respond-up('medium') {
  .scammer-page {

  }
}

@include respond('medium') {
  .scammer-page {

  }
}

@include respond-down('medium') {
  .scammer-page {
    padding-top: 0;

    &__form-wrap {
      padding-top: 40px;
    }

    &__description-title {
      font-size: 24px;
      line-height: 29/24*1em;
      margin: 40px 0 21px;
    }
  }
}
@include respond-down('small') {
  .scammer-page {

  }
}