.breadcrumbs {
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    display: block;
  }

  &__item_delimiter {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
  }

  &__link,
  &__item_delimiter-icon {
    display: flex;
  }

  &__name {
    color: var(--primary-color);
  }
}

@include respond-up("large") {
  .breadcrumbs {
    margin-top: var(--grid-spacer);

    &__list {
      margin: 0 -4px;
      height: 48px;
    }

    &__item {
      padding: 0 4px;
    }

    &__link {
      transition: color .4s;

      &:hover {
      }
    }

    &__name {
      //line-height: 48px;
    }
  }
}

@include respond("medium") {
  .breadcrumbs {
    margin-top: 33px;
  }
}

@include respond-down("medium") {
  .breadcrumbs {
    padding: 10px 0;

    &__list {
      margin: 0 -3px;
    }

    &__item {
      padding: 0 3px;

      &:last-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &_delimeter {
        font-size: 12px;
      }
    }

    &__link {
      padding: 3px;
      margin: -3px;
    }

    &__name {
      font-size: 12px;
    }
  }
}

@include respond-down("small") {
  .breadcrumbs {
  }
}
