.tabs {
  margin-bottom: var(--grid-gap);
  overflow: scroll;
  list-style: none;
  display: flex;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }

  &__tab {
    margin-right: 6px;

    &._active {
      .tabs__link {
        color: white;
        background-color: var(--primary-color);
      }
    }

    &:nth-child(n + 2) {
      margin-left: 6px;
    }
  }

  &__link {
    display: block;
    color: var(--primary-color);
    background-color: var(--primary-16);
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 16px;
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .tabs {

  }
}

@include respond-up('medium') {
  .tabs {

  }
}

@include respond('medium') {
  .tabs {

  }
}

@include respond-down('medium') {
  .tabs {
    margin: 0 calc(var(--grid-spacer) * -1) var(--grid-gap);
    padding: 0 var(--grid-spacer);
  }
}

@include respond-down('small') {
  .tabs {

  }
}