.header {
  padding-bottom: var(--header-height);

  &__outer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-bg-light);
  }

  &__inner {
    height: var(--header-height);
    display: flex;
    justify-content: space-between;
  }

  &__logo-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__logo-icon {
    display: flex;
    align-items: flex-end;
  }

  &__logo-text {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 10px;
    line-height: 12/10 * 1em;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: var(--color-yellow);
  }

  &__content {
    flex: 0 0 auto;
  }

  &__hamburger {

  }
}

@include respond-up('large') {
  .header {
    &__logo-text {
      text-align: right;
      justify-content: flex-end;
    }

    &__hamburger {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .header {

  }
}

@include respond('medium') {
  .header {

  }
}

@include respond-down('medium') {
  .header {

    &._menu-opened {
      .header {
        &__content-wrap {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    &__logo-text {
      text-align: left;
      justify-content: flex-start;
    }

    &__hamburger {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__content-wrap {
      position: fixed;
      opacity: 0;
      pointer-events: none;
      top: var(--header-height);
      left: 0;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100 - var(--header-height));
      background-color: var(--primary-16-on-bg);
      border-top: 1px solid var(--primary-16);
      z-index: 500;
      transition: {
        property: opacity;
        duration: var(--default-transition-timing);
      };
    }
  }
}

@include respond-down('small') {
  .header {

  }
}