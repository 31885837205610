.article-slider {
  overflow: hidden;

  &__wrap {
    width: 100%;
  }

  &__slider {
    width: 100%;
  }

  li.article-slider__slide {
    width: unset;
    background-color: var(--primary-16);
  }

  &__slide {
  }

  &__link {
    display: flex;
  }

  &__panel {
    display: flex;
    justify-content: center;
  }

  &__arrows {
    padding: 20px var(--grid-spacer) 0 var(--grid-spacer);
    display: flex;
    justify-content: space-between;
    flex: 0 0 290px;
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-16);
    border-radius: 100px;
    height: 40px;
    width: 40px;
    flex: 0 0 40px;

    &_left {
      svg {
        transform: rotate(90deg);
      }
    }

    &_right {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &__pagination-wrap {
    flex: 1 1 auto;
    position: relative;
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 var(--grid-spacer);
    top: calc(50% - 4px);

    .swiper-pagination-bullet {
      opacity: 1;
      background-color: var(--primary-16);
      transition: {
        property: background-color;
        duration: var(--default-transition-timing);
      };
      margin: 0 4px;
      flex: 0 0 8px;
      height: 8px;
      width: 8px;

      &-active {
        background-color: var(--primary-color);
      }
    }
  }

  &__label {
    text-align: center;
    padding: 0 var(--grid-spacer);
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    line-height: 17/14*1em;
    font-weight: 400;
    color: var(--color-bg);
  }
}

@include respond-up('large') {
  .article-slider {
    padding-top: 40px;

  }
}

@include respond-up('medium') {
  .article-slider {

  }
}

@include respond('medium') {
  .article-slider {

  }
}

@include respond-down('medium') {
  .article-slider {
    padding-top: 25px;
  }
}

@include respond-down('small') {
  .article-slider {

  }
}