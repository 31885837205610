.success-modal {
  position: relative;
  background-color: white;
  min-width: 300px;
  min-height: 300px;

  &._need-remove {
    .success-modal {

      &__inner {
        opacity: 0;
        pointer-events: none;
      }

      &__icon {
        background-color: var(--t);

        svg {
          opacity: 0;
        }
      }

      &__circle {
        transform: scale(.2);
        opacity: 0;
      }

      &__title {
        transform: translateY(15px);
        opacity: 0;
      }

      &__subtitle {
        transform: translateY(25px);
        opacity: 0;
      }
    }
  }

  &__inner {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 var(--grid-spacer);
    transition: {
      property: opacity;
      duration: var(--default-transition-timing);
    };
  }

  &__icon-wrap {
    height: 100px;
    width: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__icon {
    position: absolute;
    left: calc(50% - 35px);
    top: calc(50% - 35px);
    width: 70px;
    height: 70px;
    z-index: 30;
    border-radius: 100px;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: {
      property: background-color;
      duration: var(--default-transition-timing);
      delay: .1s;
    };

    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      transition: {
        property: opacity;
        duration: var(--default-transition-timing);
        delay: .2s;
      };
    }
  }

  &__circle {
    position: relative;
    z-index: 20;
    background-color: var(--primary-16);
    border-radius: 100px;
    height: 100px;
    width: 100px;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
    };
  }

  &__title {
    color: var(--color-bg);
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 5px;
    text-align: center;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
      delay: .1s;
    };
  }

  &__subtitle {
    color: var(--color-gray-text);
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    transition: {
      property: opacity, transform;
      duration: var(--default-transition-timing);
      delay: .2s;
    };
  }
}

