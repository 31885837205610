.capper-item {
  // ТОП-10, фильтруемый, сомнительные
  &_main-top, &_filterable, &_scammer {
    padding: 10px;

    .capper-item {
      &__name {
        padding-left: 13px;
      }

      &__number-block {
        width: 81px;
        height: 32px;
        padding-left: 3px;
      }

      &__number {
        font-size: 20px;
        line-height: 24/20*1em;
        width: 23px;
      }

      &__image-wrap {
        right: 7px;
      }
    }
  }

  // ТОП-10, сомнительные
  &_main-top, &_scammer {
    .capper-item {
      &__capper {
        grid-column: 1/5;
      }
    }
  }

  // ТОП-10, боковой
  &_main-top, &_main-side {
    &._even {
      background-color: var(--color-green-8-on-white);
    }

    .capper-item {
      &__number-block {
        background: var(--gradient-green);
      }
    }
  }

  // фильтруемый
  &_filterable {
    &._even {
      background-color: var(--color-gray-bg);
    }

    .capper-item {
      &__number-block {
        background: var(--primary-gradient);
      }
    }
  }



  // боковой
  &_main-side {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 7px 5px 5px 5px;

    .capper-item {
      &__capper {
        order: 1;
        flex: 0 0 100%;
        margin-bottom: 1px;
      }

      &__number-block {
        height: 32px;
        padding-left: 3px;
      }

      &__rating {
        order: 6;
      }

      //&__outer {
      //  order: 7;
      //
      //  .capper-item {
      //    &__outer-icon {
      //      margin-right: 3px;
      //    }
      //  }
      //}

      &__reviews {
        order: 3;
        //display: none;
      }

      &__button-wrap {
        order: 20;
        flex: 0 0 95px;
      }
    }
  }

  // сомнительные
  &_scammer {
    &._even {
      background-color: var(--color-red-6-on-white);
    }

    .capper-item {
      &__number-block {
        background: var(--red-gradient);
      }

      &__reviews-icon, &__votes-icon {
        svg path{
          &[fill] {
            fill: var(--color-red);
          }
          &[stroke] {
            stroke: var(--color-red);
          }
        }
      }

      &__votes-icon {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@include respond-up('large') {
  .capper-item {
    // ТОП-10, фильтруемый, сомнительные
    &_main-top, &_filterable, &_scammer {
      display: grid;
      grid-gap: 0 var(--grid-gap);
    }

    // ТОП-10
    &_main-top .capper-item {
      &__name {
        font-size: 20px;
        line-height: 24/20*1em;
      }

      &__rating {
        //grid-column: 6/7;
        grid-column: 5/6;
      }

      &__reviews {
        grid-column: 7/8;
      }
    }

    // ТОП-10, сомнительные
    &_main-top, &_scammer {
      //grid-template-columns: calc(var(--grid-column) - 10px) repeat(6, 1fr) 125px 95px;
      grid-template-columns: calc(var(--grid-column) - 10px) repeat(7, 1fr) calc(var(--grid-column) - 10px);

      .capper-item {
        //&__outer {
        //  grid-column: 8/9;
        //}

        &__button-wrap {
          //grid-column: 9/10;
          grid-column: 8/10;
        }
      }
    }

    // фильтруемый
    &_filterable {
      grid-template-columns:
              calc(var(--grid-column) - 10px)
              repeat(5, 1fr)
              calc(var(--grid-column2) - var(--grid-gap))
              calc(var(--grid-column) - 10px + var(--grid-gap));
              //23px
              //95px;

      .capper-item {
        &__button-wrap {
          grid-column: 8/9;
          //grid-column: 9/10;
        }

        &__name {
          font-size: 14px;
          line-height: 17/17*1em;
        }

        &__capper {
          grid-column: 1/4;
        }

        &__rating {
          grid-column: 4/5;
        }

        &__reviews {
          grid-column: 5/6;
        }

        &__votes {
          grid-column: 6/7;
        }

        &__sport {
          grid-column: 7/8;
        }

        //&__outer {
        //  grid-column: 8/9;
        //
        //  .capper-item__outer-text {
        //    display: none;
        //  }
        //}
      }
    }

    // боковой
    &_main-side {
      .capper-item {
        &__name {
          padding-left: 7px;
          font-size: 17px;
          line-height: 20/17*1em;
        }

        &__number {
          font-size: 17px;
          line-height: 20/17*1em;
          width: 20px;
        }

        &__number-block {
          width: 70px;
        }

        &__image-wrap {
          right: 3px;
        }
      }
    }

    // сомнительные
    &_scammer {
      .capper-item {
        &__reviews {
          grid-column: 6/7;
        }

        &__votes {
          grid-column: 7/8;
        }

      }
    }
  }
}

@include respond-up('medium') {
  .capper-item {

  }
}

@include respond('medium') {
  .capper-item {

  }
}

@include respond-down('medium') {
  .capper-item {
    // ТОП-10, фильтруемый, сомнительные
    &_main-top, &_filterable, &_scammer {
      .capper-item {
        &__name {
          font-size: 18px;
          line-height: 22/18*1em;
        }

        &__button-wrap {
          order: 20;
          flex: 0 0 95px;
        }
      }
    }

    // ТОП-10
    &_main-top .capper-item {
      &__rating {
        order: 6;
      }

      //&__outer {
      //  order: 7;
      //}

      &__reviews {
        //display: none;
        order: 3;
      }
    }

    // фильтруемый
    &_filterable .capper-item {
      &__capper {
        grid-column: 1/5;
      }

      &__rating {
        order: 6;
        flex: 0 0 20%;
        //flex: 0 0 auto;
      }

      &__reviews {
        order: 3;
        //flex: 0 0 30%;
        flex: 0 0 20%;
      }

      &__votes {
        //order: 11;
        order: 9;
        flex: 0 0 20%;
      }

      &__sport {
        order: 10;
        flex: 0 0 50%;
        //flex: 0 0 120px;
      }

      //&__outer {
      //  order: 9;
      //  flex: 0 0 30%;
      //  justify-content: flex-end;
      //
      //  .capper-item {
      //    &__outer-icon {
      //      margin-right: 0;
      //      margin-left: 5px;
      //    }
      //
      //    &__outer-link {
      //      flex-direction: row-reverse;
      //    }
      //  }
      //}

      &__button-wrap {
        flex: 0 0 50%;
        //flex: 0 0 120px;
      }
    }

    // боковой
    &_main-side {
      .capper-item {
        &__name {
          padding-left: 13px;
          font-size: 18px;
          line-height: 22/18*1em;
        }

        &__number {
          font-size: 20px;
          line-height: 24/20*1em;
          width: 23px;
        }

        &__number-block {
          width: 83px;
        }

        &__image-wrap {
          right: 7px;
        }
      }
    }

    // сомнительные
    &_scammer {
      .capper-item {
        &__votes {
          order: 6;
        }

        &__reviews {
          order: 3;
        }
      }
    }
  }
}

@include respond-down('small') {
  .capper-item {

  }
}