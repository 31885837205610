.capper-searcher {
  position: relative;

  &__input-wrap {
    position: relative;
  }

  input.capper-searcher__input {
    background-color: var(--primary-16);
    border-radius: 3px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 13px 36px 13px 17px;
    letter-spacing: 0.05em;
    line-height: 19/16*1em;
    font-size: 16px;
    width: 100%;

    &::placeholder {
      color: var(--primary-color);
    }
  }

  &__icon {
    position: absolute;
    display: flex;
    align-items: center;
    top: calc(50% - 10px);
    right: 13px;
  }

  &__result {
    position: absolute;
    z-index: 20;
    left: 0;
    width: 100%;
  }

  &__more, &__empty {
    text-align: center;
    background-color: white;
    color: var(--color-gray-text);
    padding: 10px;
  }
}

@include respond-up('large') {
  .capper-searcher {
    top: 100%;

    &__result {
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }

    &__result-header {
      display: none;
    }

    &__more, &__empty {
      border-radius: 0 0 6px 6px;
    }
  }
}

@include respond-up('medium') {
  .capper-searcher {

  }
}

@include respond('medium') {
  .capper-searcher {

  }
}

@include respond-down('medium') {
  .capper-searcher {
    &__result {
      bottom: 100%;
    }

    &__list {
      max-height: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--grid-spacer) - 48px - 40px);
      overflow: auto;
    }

    &__result-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: var(--primary-gradient);
      border-radius: 6px 6px 0 0;
      height: 40px;
    }

    &__result-title {
      color: white;
      font-family: var(--h-font);
      letter-spacing: 0.05em;
      font-style: italic;
      font-weight: bold;
      padding: 0 10px;
      text-transform: uppercase;
      font-size: 12px;
    }

    &__result-closer {
      color: white;
      position: relative;
      height: 40px;
      width: 40px;

    }

    &__closer-line {
      display: block;
      position: absolute;
      background-color: white;
      height: 2px;
      width: 20px;
      top: calc(50% - 1px);
      left: 10px;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }

    &__result {
      box-shadow: 0px -5px 8px rgba(0, 0, 0, 0.25);
    }

    &__empty {
      border-radius: 6px 6px 0 0;
    }
  }
}

@include respond-down('small') {
  .capper-searcher {

  }
}