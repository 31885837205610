.floating-field {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;

  label {
    position: absolute;
    left: 20px;
    right: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: var(--color-gray-text);
    background-color: transparent;
    transition: all 0.2s;
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 18/15*1em;
    top: 15px;
  }

  input{
    &::placeholder {
      transition: ease-in opacity .2s;
      opacity: 0;
    }
  }

  &._filled label,
  input:focus + label,
  textarea:focus + label {
    font-size: 12px;
    color: black;
    background-color: var(--light-gray-color);
    width: fit-content;
    transform: translate3d(-20px, -35px, 0);
    //padding: 0 8px;
  }

  input:focus{
    &::placeholder {
      opacity: 1;
    }
  }

  textarea {
    resize: none !important;
  }
}

@include respond-up('large') {
  .floating-field {
    &:last-child{
      margin: 0;
    }
  }
}

@include respond-up('medium') {
  .floating-field {
    margin-bottom: 35px;
  }
}

@include respond-down('medium') {
  .floating-field {
    label {
      top: 16px;
    }
  }
}

@include respond-down('small') {
  .floating-field {
    margin-bottom: 30px;

    &:last-child{
      margin: 0;
    }

    &._filled label,
    input:focus + label,
    textarea:focus + label,
    textarea:focus + label {
      font-size: 12px;
      height: 12px;
    }
  }
}