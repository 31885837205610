.capper-info {
  display: grid;

  &__top {
  }

  &__bottom {

  }

  &__side {

  }

  &__vote-block {
    display: flex;
    justify-content: center;
    padding: 25px 0;
    flex-direction: column;
  }

  &__vote-label {
    font-weight: 700;
    font-size: 15px;
    line-height: 18/15;
    letter-spacing: 0.05em;
    text-align: center;
  }

  &__stars {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 10px;
  }

  &__star {
    &._fill {
      svg path{
        fill: var(--color-yellow);
      }
    }

    &:hover {
      svg path, &~.capper-info__star svg path{
        fill: var(--color-yellow);
        opacity: .3;
      }
    }
  }

  &__star-link {
    display: flex;
    align-items: center;
    width: 28px;
    padding: 0 3px;

    svg {
      width: 100%;
      display: flex;
      align-items: center;

      path {
        fill: var(--t);
        transition: {
          property: fill, opacity;
          duration: var(--default-transition-timing);
        };
      }
    }
  }
}

@include respond-up('large') {
  .capper-info {
    grid-template-columns: repeat(9, 1fr);
    border-radius: 6px;
    overflow: hidden;

    &__top {
      grid-column: 1/8;
      grid-row: 1/2;
    }

    &__bottom {
      grid-column: 1/8;
      grid-row: 2/4;

      background-color: var(--primary-16);
    }

    &__side {
      grid-column: 8/10;
      grid-row: 1/3;
    }

    &__vote-block {
      grid-column: 8/10;
      grid-row: 3/4;

      height: 97px;
      background-color: white;
      //border-radius: 0 0 6px 0;
    }

    &__star-link {
      width: 28px;
      padding: 0 3px;
    }
  }
}

@include respond-up('medium') {
  .capper-info {

  }
}

@include respond('medium') {
  .capper-info {

  }
}

@include respond-down('medium') {
  .capper-info {
    margin: 0 calc(var(--grid-spacer) * -1);

    &__top {
      grid-row: 1/2;
    }


    &__bottom {
      grid-row: 3/4;
    }

    &__side {
      grid-row: 2/3;
    }

    &__vote-block {
      grid-row: 4/5;
      background-color: white;
    }

    &__star-link {
      width: 45px;
      padding: 0 5px;
    }
  }
}

@include respond-down('small') {
  .capper-info {

  }
}